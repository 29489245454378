import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    Box,
    Card,
    CardActions,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Button,
    Grid,
    Link,
} from '@material-ui/core';
import CopyRight from 'components/CopyRight';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: colors.lightGrey,
    },
    innerContainer: {
        padding: '40px 56px',
        width: 'calc(100% - 120px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const Results: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Paper className={classes.innerContainer} elevation={1}>
                <div id="searchsg-result-container" />
            </Paper>
            <CopyRight />
        </div>
    );
};

export default Results;
